<template>
    <div class="kath-sz-card">
        <div class="subheading mb-3">
            {{table.title}}
        </div>
        <v-data-table
                :headers="table.headers"
                :items="table.data"
                items-per-page="25"
                class="elevation-1"
        >
            <template slot="items" slot-scope="props">
                <tr>
                    <td>{{ props.item.label }}</td>
                    <td>{{ props.item.yes }}</td>
                    <td>{{ props.item.no }}</td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "VoteTable",
        props: ['table']
    }
</script>

<style scoped>

</style>